import { useEffect, useRef } from 'react'

import * as THREE from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import { fragmentShader } from '../../threejs/shaders/fragment'
import { vertexShader } from '../../threejs/shaders/vertex.js'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import Container from './Container'

function Loader( { projectLogo } ) {
    const backgroundRef = useRef( null )
    const RemoveBackgroundRef = useRef( null )
    useEffect( () => {
        let scene = new THREE.Scene()
        let offset = new THREE.Vector2( 0, 0 )
        let background = backgroundRef.current
        let perspective = 0.8
        let height = window.innerHeight / 2
        let width = window.innerWidth / 2

        let fov = ( 180 * Math.atan( height / 2 / perspective ) ) / Math.PI
        let camera = new THREE.PerspectiveCamera( fov, width / height, 0.001, 1000 )
        camera.position.set( 0, 0, perspective )
        let renderer = new THREE.WebGL1Renderer( { antialias: true, alpha: true } )
        renderer.setSize( width, height )
        renderer.setPixelRatio( Math.min( window.devicePixelRatio, 2 ) )
        let composer = new EffectComposer( renderer )
        composer.addPass( new RenderPass( scene, camera ) )
        background.appendChild( renderer.domElement )
        let geometry = new THREE.SphereBufferGeometry( 1.5, 32, 32 )
        let material = new THREE.ShaderMaterial
        (
          {
            extensions:
            {
              derivatives: '#extention GL_OES_standart_derivatives: enable'
            },
            side: THREE.DoubleSide,
            uniforms:
            {
              time: { value: 0 },
              resolution: { value: new THREE.Vector4() }
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader
            }
        )
        let mesh = new THREE.Mesh( geometry, material )
        mesh.position.set( offset.x, offset.y )
        scene.add( mesh )
        const render = () =>
        {
          material.uniforms.time.value += 0.01

          requestAnimationFrame( render )
          // renderer.render( scene, camera )
          composer.render( scene, camera )
        }
        const onWindowResize = () =>
        {
          camera.aspect = width / height
          camera.fov = ( 180 * Math.atan( height / 2 / perspective ) ) / Math.PI
          camera.updateProjectionMatrix()
          renderer.setSize( width, height )
          renderer.setPixelRatio( Math.min( window.devicePixelRatio, 2 ) )
        }
        window.addEventListener( 'resize', onWindowResize, false )
        render()
        return () => background.removeChild( renderer.domElement )
    }, [] )
    useEffect( () => {
      setTimeout( () => {
        RemoveBackgroundRef.current.style.opacity = '0'
      }, 2600 )
    }, [] )
    return (
        <>
            <div ref={ RemoveBackgroundRef } className='loader-content'>
                <Container classes={ 'loader-content-container' } flex flexColumn flexCenter alignCenter>
                    <img src={ projectLogo } height='3rem' width='6rem' title='Project Logo' name='Project Logo' alt='Project Logo' />
                </Container>
                <Container classes={ 'loader-content-text-container' } flex flexColumn flexCenter alignCenter>
                  <p>LOADING</p>
                </Container>
                <div ref={ backgroundRef } className='loader-container-background-one' />
                <div className='loader-container-background-vignette' />
            </div>
        </>
    )
}

Loader.defaultProps =
{
  projectLogo: './image/project_logo/project_logo.svg'
}

export default Loader
