import { useRef } from 'react'
import PropTypes from 'prop-types'
import Container from '../Shared/Container'
import Title from '../Shared/Title'
import UseElementOnScreen from '../../hooks/UseElementOnScreen'
import './OfferSection.css'

function OfferSection( {
    offerTopTitle,
    mobilityPlayersIcon,
    mobilityPlayersText,
    luxuryCarsIcon,
    luxuryCarsText,
    industriesIcon,
    industriesText,
    oemIcon,
    oemText,
    designIcon,
    designText,
    classicCarsIcon,
    classicCarsText
} ) {
    const targetRef = useRef( null )
    const isVisible = UseElementOnScreen( {
            root: null,
            rootMargin: '0px',
            threshold: 0.2
    }, targetRef )
    return (
        <section id='offer'>
            <Container useRef={ targetRef } classes={ 'offer-section' } flex flexColumn gapLarge>
                <Title size={ !isVisible ? 'large offer-section-title' : 'large offer-section-title is-visible'}>
                    <h1>{ offerTopTitle }</h1>
                </Title>
                <Container classes={ 'offer-section-content' } flex flexColumn>
                    <Container classes={ 'offer-section-row' } flex spaceBetween>
                        <Container classes={ 'offer-section-mobility-players' } flex flexColumn>
                            <img src={ mobilityPlayersIcon } className={ !isVisible ? 'offer-section-mobility-players-icon' : 'offer-section-mobility-players-icon is-visible' } width='100%' height='100%' loading='lazy' title='mobility payer' alt='mobility payer' />
                            <p className={ !isVisible ? 'offer-section-mobility-players-text' : 'offer-section-mobility-players-text is-visible' }>{ mobilityPlayersText }</p>
                        </Container>
                        <Container classes={ 'offer-section-luxury-cars' } flex flexColumn>
                            <img src={ luxuryCarsIcon } className={ !isVisible ? 'offer-section-luxury-cars-icon' : 'offer-section-luxury-cars-icon is-visible' } width='100%' height='100%' loading='lazy' title='mobility payer' alt='mobility payer' />
                            <p className={ !isVisible ? 'offer-section-luxury-cars-text' : 'offer-section-luxury-cars-text is-visible' }>{ luxuryCarsText }</p>
                        </Container>
                        </Container>
                    <Container classes={ 'offer-section-industries' } flex flexColumn>
                        <img src={ industriesIcon } className={ !isVisible ? 'offer-section-industries-icon' : 'offer-section-industries-icon is-visible' } width='100%' height='100%' loading='lazy' title='mobility payer' alt='mobility payer' />
                        <p className={ !isVisible ? 'offer-section-industries-text' : 'offer-section-industries-text is-visible' }>{ industriesText }</p>
                    </Container>
                    <Container classes={ 'offer-section-row' } flex spaceBetween>
                        <Container classes={ 'offer-section-oem' } flex flexColumn>
                            <img src={ oemIcon } className={ !isVisible ? 'offer-section-oem-icon' : 'offer-section-oem-icon is-visible' } width='100%' height='100%' loading='lazy' title='mobility payer' alt='mobility payer' />
                            <p className={ !isVisible ? 'offer-section-oem-text' : 'offer-section-oem-text is-visible' }>{ oemText }</p>
                        </Container>
                        <Container classes={ 'offer-section-design' } flex flexColumn>
                            <img src={ designIcon } className={ !isVisible ? 'offer-section-design-icon' : 'offer-section-design-icon is-visible' } width='100%' height='100%' loading='lazy' title='mobility payer' alt='mobility payer' />
                            <p className={ !isVisible ? 'offer-section-design-text' : 'offer-section-design-text is-visible' }>{ designText }</p>
                        </Container>
                    </Container>
                    <Container classes={ 'offer-section-classic-cars' } flex flexColumn>
                        <img src={ classicCarsIcon } className={ !isVisible ? 'offer-section-classic-cars-icon' : 'offer-section-classic-cars-icon is-visible' } width='100%' height='100%' loading='lazy' title='mobility payer' alt='mobility payer' />
                        <p className={ !isVisible ? 'offer-section-classic-cars-text' : 'offer-section-classic-cars-text is-visible' }>{ classicCarsText }</p>
                    </Container>
                </Container>
                <div className={ !isVisible ? 'offer-section-background-top' : 'offer-section-background-top is-visible' } />
                <div className='offer-section-background-container'>
                    <div className={ !isVisible ? 'offer-section-background-center-one' : 'offer-section-background-center-one is-visible' } />
                    <div className={ !isVisible ? 'offer-section-background-center-two' : 'offer-section-background-center-two is-visible' } />
                </div>
                <div className={ !isVisible ? 'offer-section-background-bottom' : 'offer-section-background-bottom is-visible' } />
            </Container>
        </section>
    )
}

OfferSection.defaultProps =
    {
        offerTopTitle: 'We can offer our services to different industry segments',
        mobilityPlayersIcon: './image/offer/mobility_players.svg',
        mobilityPlayersText: 'Mobility players: new EV startups, autonomous vehicles.',
        luxuryCarsIcon: './image/offer/luxury_cars.svg',
        luxuryCarsText: 'Luxury cars, super / hyper-cars, boutique manufacturers.',
        industriesIcon: './image/offer/other_industries.svg',
        industriesText: 'Other industries: anything that needs automotive grade glazing in a non-automotive industry.',
        oemIcon: './image/offer/oem_traditional.svg',
        oemText: 'Traditional OEMs: advanced design, innovation, concept cars departments.',
        designIcon: './image/offer/design_styling.svg',
        designText: 'Design / styling studios.',
        classicCarsIcon: './image/offer/classic_cars.svg',
        classicCarsText: 'Old-timers & classic cars.'
    }

OfferSection.propTypes =
    {
        offerTopTitle: PropTypes.string,
        mobilityPlayersIcon: PropTypes.string,
        mobilityPlayersText: PropTypes.string,
        luxuryCarsIcon: PropTypes.string,
        luxuryCarsText: PropTypes.string,
        industriesIcon: PropTypes.string,
        industriesText: PropTypes.string,
        oemIcon: PropTypes.string,
        oemText: PropTypes.string,
        designIcon: PropTypes.string,
        designText: PropTypes.string
    }

export default OfferSection
