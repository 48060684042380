import { useRef } from 'react'
import NavigationList from './NavigationList'
import UseElementOnScreen from '../../hooks/UseElementOnScreen'
import './Navigation.css'

function Navigation( { route } ) {
    const targetRef = useRef( null )
    const isVisible = UseElementOnScreen( {
        root: null,
        rootMargin: '0px',
        threshold: 0.2
    }, targetRef )
    return (
        <nav>
            <div ref={ targetRef } className={ !isVisible ? 'navigation-container' : 'navigation-container is-visible' } style={ { height: ( route === 'home' ) ? '30rem' : '40rem' } }>
                <NavigationList route={ route } />
            </div>
        </nav>
    )
}

export default Navigation
