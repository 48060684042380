import Container from '../../Shared/Container'
import { SALES_LEGAL_TERMS_DATA } from '../../../data/salesLegalTermsData'

import './introduction.styles.css'

const IntroductionSection = ( { id } ) => {
    const { content } = SALES_LEGAL_TERMS_DATA.introduction

    return (
        <Container classes={ 'introduction-section' } flex flexColumn id={ id }>
            {
                content.map( ( paragraph, index ) => (
                <p key={ index }>{ paragraph.text }</p>
                ) )
            }
        </Container>
    )
}

export default IntroductionSection
