import { useRef } from 'react'
import PropTypes from 'prop-types'
import Container from '../Shared/Container'
import Title from '../Shared/Title'
import ButtonMailTo from '../Shared/ButtonMailTo'
import UseElementOnScreen from '../../hooks/UseElementOnScreen'
import './OurStrengthSection.css'

function OurStrengthSection( {
    strengthTopTitle,
    strengthBottomTitle,
    strengthQualityTopTitle,
    strengthQualityBottomTitle,
    strengthQualityTopText,
    strengthQualityBottomText,
    buttonText,
    strengthImage,
    rectElement,
    ellipseElement
  } ) {
  const targetRef = useRef( null )
  const isVisible = UseElementOnScreen( {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
  }, targetRef )
  return (
    <section id='strength'>
      <Container useRef={ targetRef } classes={ 'our-strength-section' } flex flexColumn alignCenter>
        <Title size={ !isVisible ? 'medium' : 'medium is-visible' }>
          <h4>{ strengthTopTitle }</h4>
          <h3>{ strengthBottomTitle }</h3>
        </Title>
        <Container classes={ 'our-strength-section-block-strength-quality' } flex>
          <div className={ !isVisible ? 'our-strength-section-block-strength-quality-image-container' : 'our-strength-section-block-strength-quality-image-container is-visible' }>
            <img src={ strengthImage } className={ !isVisible ? 'our-strength-section-block-strength-quality-image' : 'our-strength-section-block-strength-quality-image is-visible' } width='100%' height='100%' loading='lazy' title={ strengthBottomTitle } alt={ strengthBottomTitle } />
          </div>
          <div className={ !isVisible ? 'our-strength-section-block-strength-quality-element-container' : 'our-strength-section-block-strength-quality-element-container is-visible' }>
            <img src={ rectElement } className='our-strength-section-block-strength-quality-element-left' width='100%' height='100%' loading='lazy' title={ strengthBottomTitle } alt='rect element' />
            <img src={ ellipseElement } className='our-strength-section-block-strength-quality-element-right' width='100%' height='100%' loading='lazy' title={ strengthBottomTitle } alt='ellipse elements' />
          </div>
          <div className='our-strength-section-block-strength-quality-content-container'>
            <Title size={ !isVisible ? 'small' : 'small is-visible' }>
              <h4 className={ 'title-h6' }>{ strengthQualityTopTitle }</h4>
              <h5>{ strengthQualityBottomTitle }</h5>
            </Title>
            <p className={ !isVisible ? 'our-strength-section-block-strength-quality-content-text-top' : 'our-strength-section-block-strength-quality-content-text-top is-visible' }>{ strengthQualityTopText }</p>
            <p className={ !isVisible ? 'our-strength-section-block-strength-quality-content-text-bottom' :'our-strength-section-block-strength-quality-content-text-bottom is-visible' }>{ strengthQualityBottomText }</p>
            <ButtonMailTo mailto={ 'mailto:reeflect-glass@agc.com' } state={ !isVisible ? 'primary' : 'primary is-visible' }>{ buttonText }</ButtonMailTo>
            <div className={ !isVisible ? 'our-strength-section-block-strength-quality-content-background' : 'our-strength-section-block-strength-quality-content-background is-visible' } />
          </div>
        </Container>
      </Container>
    </section>
  )
}

OurStrengthSection.defaultProps =
  {
    strengthTopTitle: 'Our',
    strengthBottomTitle: 'Strengths',
    strengthQualityTopTitle: 'Quality',
    strengthQualityBottomTitle: '& Expertise',
    strengthQualityTopText: 'Automotive grade glass: whether it is the shape, the color, the thickness or the surface finish, we provide high quality products that are representative of exterior glazing mass production process.',
    strengthQualityBottomText: 'We have assembled a team with a high level of glass expertise to support your development. As a glazing competence center, we can offer technical training to you or your team.',
    buttonText: 'Contact Us',
    strengthImage: './image/strength_section/strength_quality.jpg',
    rectElement: './image/elements/rect.svg',
    ellipseElement: './image/elements/ellipse.svg',
  }

OurStrengthSection.propTypes =
  {
    strengthTopTitle: PropTypes.string,
    strengthBottomTitle: PropTypes.string,
    strengthQualityTopTitle: PropTypes.string,
    strengthQualityBottomTitle: PropTypes.string,
    strengthQualityTopText: PropTypes.string,
    strengthQualityBottomText: PropTypes.string,
    buttonText: PropTypes.string,
    strengthImage: PropTypes.string,
    rectElement: PropTypes.string,
    ellipseElement: PropTypes.string
  }

export default OurStrengthSection
