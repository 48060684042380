import Title from '../../Shared/Title'
import Container from '../../Shared/Container'
import { SALES_LEGAL_TERMS_DATA } from '../../../data/salesLegalTermsData'

import './terms.styles.css'

const TermsSection = ( { id } ) => {
    const { content } = SALES_LEGAL_TERMS_DATA.terms

    return (
        content.map( ( clause, index ) => (
            <Container key={ index } classes={ 'terms-section' } flex flexColumn id={ id }>
                <Title size={ 'terms-section-title' }>
                    <h5 key={ index }>{ clause.title }</h5>
                </Title>
                <p className={ 'terms-section-text' }>{ clause.text }</p>
                {
                    ( clause.list.length > 0 )
                    ?
                    <>
                    {
                        clause.list.map( ( text, index ) => (
                            <p key={ index } className={ 'terms-section-bullet' }>{ text }</p>
                        ) )
                    }
                    </>
                    :
                    null
                }
            </Container>
        ) )
    )
}

export default TermsSection
