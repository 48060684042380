import PropTypes from 'prop-types'
import Container from '../Shared/Container'
import './Footer.css'

function Footer( { copyright, devLogoSource } ) {
    return (
        <footer>
            <Container classes={ 'footer-container' } flex spaceBetween>
                <p className='footer-year'>
                    { copyright } { new Date().getUTCFullYear() }
                </p>
                <Container flex spaceBetween classes={ 'footer-container-link' }>
                    <div className='footer-label'>
                        <span className='footer-label-text'>
                            Reeflect by AGC follows AGC Glass Europe policies
                        </span>
                    </div>
                    <a href='https://www.agc-glass.eu/en/general-terms-of-sale' className='footer-dev-logo' target='_blank' rel='noreferrer'>
                        General terms of sales
                    </a>
                    <a href='https://www.agc-glass.eu/en/legal-notice' className='footer-dev-logo' target='_blank' rel='noreferrer'>
                        Legal terms
                    </a>
                    <a href='https://www.agc-glass.eu/en/legal-notice#privacy#cookies' className='footer-dev-logo' target='_blank' rel='noreferrer'>
                        Privacy policy
                    </a>
                    <a href='https://www.agc-glass.eu/en/legal-notice#cookies' className='footer-dev-logo' target='_blank' rel='noreferrer'>
                        Cookies
                    </a>
                    <a href='https://www.io-source.com/' className='footer-dev-logo' target='_blank' rel='noreferrer'>
                        <img src={ devLogoSource } width='100%' height='100%' loading='lazy' title='developer' alt='developer' />
                    </a>
                </Container>
            </Container>
        </footer>
    )
}

Footer.defaultProps =
    {
        copyright: 'Copyright ©',
        devLogoSource: './image/dev_logo/dev_logo.svg'
    }

Footer.propTypes =
    {
        copyright: PropTypes.string,
        devLogoSource: PropTypes.string
    }

export default Footer
