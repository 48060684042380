import PropTypes from 'prop-types'

function Container( {
    children,
    classes,
    flex,
    grid,
    flexColumn,
    spaceBetween,
    flexCenter,
    alignCenter,
    alignEnd,
    gapSmall,
    gapMedium,
    gapLarge,
    white,
    repeatOne,
    repeatTwo,
    repeatThree,
    gridGapSmall,
    gridGapMedium,
    gridGapLarge,
    marginOneRem,
    marginTwoRem,
    marginThreeRem,
    marginFourRem,
    marginFiveRem,
    paddingOneRem,
    paddingTwoRem,
    paddingThreeRem,
    paddingFourRem,
    paddingFiveRem,
    useRef,
    onScroll,
    ...otherProps
  } ) {
  return (
    <div
      ref={ useRef }
      className={ classes }
      onScroll={ onScroll }
      { ...otherProps }
      style={
        {
          display: flex ? flex && 'flex' : grid ? grid && 'grid' : null,
          gridTemplateColumns: repeatTwo ? repeatTwo && 'repeat(2, 1fr)' : repeatThree ? repeatThree && 'repeat(3, 1fr)' : repeatOne ? repeatOne && 'repeat(1fr)' : null,
          gridGap: gridGapSmall ? gridGapSmall && '1rem' : gridGapMedium ? gridGapMedium && '2rem' : gridGapLarge ? gridGapLarge && '3rem' : null,
          flexDirection: flexColumn && 'column',
          justifyContent: spaceBetween ? spaceBetween && 'space-between' : flexCenter ? flexCenter && 'center' : null,
          alignItems: alignCenter ? alignCenter && 'center' : alignEnd ? alignEnd && 'flex-end' : null,
          gap: gapSmall ? gapSmall && '1rem' : gapMedium ? gapMedium && '2rem' : gapLarge ? gapLarge && '3rem' : null,
          color: white ? 'white' : null,
          margin: marginOneRem ? marginOneRem && '1rem' : marginTwoRem ? marginTwoRem && '2rem' : marginThreeRem ? marginThreeRem && '3rem' : marginFourRem ? marginFourRem && '4rem' : marginFiveRem ? marginFiveRem && '5rem' : null,
          padding: paddingOneRem ? paddingOneRem && '1rem' : paddingTwoRem ? paddingTwoRem && '2rem' : paddingThreeRem ? paddingThreeRem && '3rem' : paddingFourRem ? paddingFourRem && '4rem' : paddingFiveRem ? paddingFiveRem && '5rem' : null,
        }
      }
    >
      { children }
    </div>
  )
}

Container.defaultProps =
  {
    classes: false,
    flex: false,
    flexColumn: false,
    spaceBetween: false,
    alignCenter: false,
    gapMedium: false,
    white: false,
    grid: false,
    flexCenter: false,
    alignEnd: false,
    gapSmall: false,
    gapLarge: false,
    repeatOne: false,
    repeatTwo: false,
    repeatThree: false,
    gridGapSmall: false,
    gridGapMedium: false,
    gridGapLarge: false,
    marginOneRem: false,
    marginTwoRem: false,
    marginThreeRem: false,
    marginFourRem: false,
    marginFiveRem: false,
    paddingOneRem: false,
    paddingTwoRem: false,
    paddingThreeRem: false,
    paddingFourRem: false,
    paddingFiveRem: false,
  }

Container.propTypes =
  {
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
    flex: PropTypes.bool,
    flexColumn: PropTypes.bool,
    spaceBetween: PropTypes.bool,
    alignCenter: PropTypes.bool,
    gapMedium: PropTypes.bool,
    white: PropTypes.bool,
    grid: PropTypes.bool,
    flexCenter: PropTypes.bool,
    alignEnd: PropTypes.bool,
    gapSmall: PropTypes.bool,
    gapLarge: PropTypes.bool,
    repeatTwo: PropTypes.bool,
    repeatThree: PropTypes.bool,
    gridGapSmall: PropTypes.bool,
    gridGapMedium: PropTypes.bool,
    gridGapLarge: PropTypes.bool,
    marginOneRem: PropTypes.bool,
    marginTwoRem: PropTypes.bool,
    marginThreeRem: PropTypes.bool,
    marginFourRem: PropTypes.bool,
    marginFiveRem: PropTypes.bool,
    paddingOneRem: PropTypes.bool,
    paddingTwoRem: PropTypes.bool,
    paddingThreeRem: PropTypes.bool,
    paddingFourRem: PropTypes.bool,
    paddingFiveRem: PropTypes.bool
  }

export default Container
