import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function LinkButton( { linkTo, children, state, leftImage, rightImage } ) {
    return (
        <Link className={ `btn btn-${ state }` } to={ linkTo }>
            <img src={ leftImage } width='100%' height='100%' loading='lazy' title={ children } alt={ children } />
            { children }
            <img src={ rightImage } width='100%' height='100%' loading='lazy' title={ children } alt={ children } />
        </Link>
    )
}

LinkButton.defaultProps =
    {
        state: 'primary',
        leftImage: './image/button/left_image.svg',
        rightImage: './image/button/right_image.svg'
    }

LinkButton.propTypes =
    {
        linkTo: PropTypes.string,
        state: PropTypes.string,
        children: PropTypes.node,
        leftImage: PropTypes.string,
        rightImage: PropTypes.string
    }

export default LinkButton
