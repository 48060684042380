import PropTypes from 'prop-types'

function Title( { size, children } ) {
    return (
        <div className={ `title-${ size }` }>
            { children }
        </div>
    )
}

Title.defaultProps =
    {
        size: 'medium'
    }

Title.propTypes =
    {
        children: PropTypes.node.isRequired,
        size: PropTypes.string
    }

export default Title
