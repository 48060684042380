const NavigationData = [
    {
        id: 'home',
        icon: './image/icon/navigation_icon.svg',
        title: 'Home'
    },
    {
        id: 'about',
        icon: './image/icon/navigation_icon.svg',
        title: 'About'
    },
    {
        id: 'strength',
        icon: './image/icon/navigation_icon.svg',
        title: 'Strengths'
    },
    {
        id: 'offer',
        icon: './image/icon/navigation_icon.svg',
        title: 'Markets'
    },
    {
        id: 'contact',
        icon: './image/icon/navigation_icon.svg',
        title: 'Contact'
    }
]

export default NavigationData
