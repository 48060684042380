import Container from '../../Shared/Container'
import Title from '../../Shared/Title'
import './hero.styles.css'

const HeroSection = ( { id } ) => {
    return (
        <Container classes={ 'hero-section' } flex spaceBetween id={ id }>
            <Title size={ 'large is-visible' }>
                <h2>General Terms of Sale</h2>
                <h3>of the AGC Glass Europe Group</h3>
            </Title>
        </Container>
    )
}

export default HeroSection
