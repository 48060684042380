import { useRef } from 'react'
import PropTypes from 'prop-types'
import Container from '../Shared/Container'
import Title from '../Shared/Title'
import UseElementOnScreen from '../../hooks/UseElementOnScreen'
import './OurStrengthSection.css'

function OurStrengthSection( {
    flexibilityTopTitle,
    flexibilityBottomTitle,
    flexibilityTopText,
    flexibilityBottomText,
    flexibilityImage,
    atomElementLeft,
    atomElementRight
  } ) {
  const targetRef = useRef( null )
  const isVisible = UseElementOnScreen( {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
  }, targetRef )
  return (
    <section>
      <Container useRef={ targetRef } classes={ 'our-strength-section' } flex flexColumn alignCenter>
        <Container classes={ 'our-strength-section-block-flexibility' } flex>
          <div className={ !isVisible ? 'our-strength-section-block-flexibility-image-container' : 'our-strength-section-block-flexibility-image-container is-visible' }>
            <img src={ flexibilityImage } className={ !isVisible ? 'our-strength-section-block-flexibility-image' : 'our-strength-section-block-flexibility-image is-visible' } width='100%' height='100%' loading='lazy' title={ flexibilityBottomTitle } alt={ flexibilityBottomTitle } />
          </div>
          <div className={ !isVisible ? 'our-strength-section-block-flexibility-element-container' : 'our-strength-section-block-flexibility-element-container is-visible' }>
            <img src={ atomElementLeft } className='our-strength-section-block-flexibility-element-left' width='100%' height='100%' loading='lazy' title={ flexibilityBottomTitle } alt='atom elements' />
            <img src={ atomElementRight } className='our-strength-section-block-flexibility-element-right' width='100%' height='100%' loading='lazy' title={ flexibilityBottomTitle } alt='atom elements' />
          </div>
          <div className='our-strength-section-block-flexibility-content-container'>
            <Title size={ !isVisible ? 'small flexibility-title' : 'small is-visible flexibility-title' }>
              <h6>{ flexibilityTopTitle }</h6>
              <h5>{ flexibilityBottomTitle }</h5>
            </Title>
            <p className={ !isVisible ? 'our-strength-section-block-flexibility-content-text-top' : 'our-strength-section-block-flexibility-content-text-top is-visible' }>{ flexibilityTopText }</p>
            <p className={ !isVisible ? 'our-strength-section-block-flexibility-content-text-bottom' : 'our-strength-section-block-flexibility-content-text-bottom is-visible' }>{ flexibilityBottomText }</p>
            <div className={ !isVisible ? 'our-strength-section-block-flexibility-content-background' : 'our-strength-section-block-flexibility-content-background is-visible' } />
          </div>
        </Container>
      </Container>
    </section>
  )
}

OurStrengthSection.defaultProps =
  {
    flexibilityTopTitle: 'Flexibility',
    flexibilityBottomTitle: 'Of a start-up',
    flexibilityTopText: 'We can offer unique samples or concepts, very small glass prototypes batches or small series to support your developments.',
    flexibilityBottomText: 'Our agile team is extremely reactive to manage your requests and deliver in the shortest lead-time.',
    flexibilityImage: './image/strength_section/flexibility.jpg',
    atomElementLeft: './image/elements/atom_left.svg',
    atomElementRight: './image/elements/atom_right.svg',
  }

OurStrengthSection.propTypes =
  {
    flexibilityTopTitle: PropTypes.string,
    flexibilityBottomTitle: PropTypes.string,
    flexibilityTopText: PropTypes.string,
    flexibilityBottomText: PropTypes.string,
    flexibilityImage: PropTypes.string,
    atomElementLeft: PropTypes.string,
    atomElementRight: PropTypes.string
  }

export default OurStrengthSection
