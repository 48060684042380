import { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-scroll'
import Container from '../Shared/Container'
import NavigationItem from '../Navigation/NavigationItem'
import NavigationData from '../../data/NavigationData'
import { Link as RouterLink } from 'react-router-dom'
import './Header.css'

function Header( { projectLogoSource, mobileMenuIcon, mobileMenuCloseIcon, home } ) {
    const [ mobileMenu, setMobileMenu ] = useState( false )
    const [ resize, setResize ] = useState( false )
    const handleMenu = () =>
    {
        setMobileMenu( !mobileMenu )
    }
    const handleResize = () =>
    {
        if ( window.scrollY >= 80 )
        {
            setResize( true )
        } else
        {
            setResize( false )
        }
    }
    window.addEventListener( 'scroll', handleResize )

    return (
        <header>
            <Container classes={ resize ? 'header-container' : 'header-container active' } flex spaceBetween>
                {
                    home
                    ?
                    <Link to={ 'home' } spy={ true } className='header-project-logo'>
                        <img src={ projectLogoSource } width='100%' height='5rem' loading='eager' title='company' alt='company' />
                    </Link>
                    :
                    <RouterLink to={ '/' } className='header-project-logo'>
                        <img src={ projectLogoSource } width='100%' height='5rem' loading='eager' title='company' alt='company' />
                    </RouterLink>
                }
                <div onClick={ handleMenu } className='header-container-mobile-menu'>
                    <img src={ mobileMenuIcon } width='100%' height='100%' loading='eager' title='mobile' alt='mobile' />
                </div>
            </Container>
                <Container classes={ mobileMenu ? `header-mobile-menu active` : `header-mobile-menu` } flex alignCenter>
                    <Container classes={ 'header-mobile-menu-content' } flex flexColumn gapLarge marginThreeRem>
                        { NavigationData.map(
                            ( item, index ) =>
                                (
                                    <NavigationItem key={ index } item={ item } handleClick={ handleMenu } />
                                )
                            )
                        }
                        <div className='header-mobile-menu-background' />
                        <div onClick={ handleMenu } className={ mobileMenu ? 'header-mobile-menu-close active' : 'header-mobile-menu-close' }>
                            <img src={ mobileMenuCloseIcon } width='100%' height='100%' loading='eager' title='mobile' alt='mobile' />
                        </div>
                    </Container>
                </Container>
        </header>
    )
}

Header.defaultProps =
    {
        projectLogoSource: './image/project_logo/project_logo.svg',
        mobileMenuIcon: './image/icon/mobile_menu_icon.svg',
        mobileMenuCloseIcon: './image/icon/mobile-menu-close-icon.svg'
    }

Header.propTypes =
    {
        projectLogoSource: PropTypes.string
    }

export default Header
