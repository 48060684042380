import { useRef } from 'react'
import PropTypes from 'prop-types'
import Container from '../Shared/Container'
import Title from '../Shared/Title'
import UseElementOnScreen from '../../hooks/UseElementOnScreen'
import './OurStrengthSection.css'

function OurStrengthSection( {
    innovativeQualityTopTitle,
    innovativeQualityBottomTitle,
    innovativeQualityTopText,
    innovativeQualityBottomText,
    innovativeQualityImage
  } ) {
  const targetRef = useRef( null )
  const isVisible = UseElementOnScreen( {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
  }, targetRef )
  return (
    <section>
      <Container useRef={ targetRef } classes={ 'our-strength-section' } flex flexColumn alignCenter>
        <Container classes={ 'our-strength-section-block-innovative-products' } flex>
          <div className='our-strength-section-innovative-products-quality-content-container'>
            <Title size={ !isVisible ? 'small' : 'small is-visible' }>
              <h6>{ innovativeQualityTopTitle }</h6>
              <h5>{ innovativeQualityBottomTitle }</h5>
            </Title>
            <p className={ !isVisible ? 'our-strength-section-innovative-products-quality-content-text-top' : 'our-strength-section-innovative-products-quality-content-text-top is-visible' }>{ innovativeQualityTopText }</p>
            <p className={ !isVisible ? 'our-strength-section-innovative-products-quality-content-text-bottom' : 'our-strength-section-innovative-products-quality-content-text-bottom is-visible' }>{ innovativeQualityBottomText }</p>
            <div className={ !isVisible ? 'our-strength-section-innovative-products-quality-content-top-background' : 'our-strength-section-innovative-products-quality-content-top-background is-visible' } />
            <div className={ !isVisible ? 'our-strength-section-innovative-products-quality-content-bottom-background' : 'our-strength-section-innovative-products-quality-content-bottom-background is-visible' } />
          </div>
          <div className={ !isVisible ? 'our-strength-section-block-innovative-products-image-container' : 'our-strength-section-block-innovative-products-image-container is-visible' }>
            <img src={ innovativeQualityImage } className={ !isVisible ? 'our-strength-section-innovative-products-quality-image' : 'our-strength-section-innovative-products-quality-image is-visible' } width='100%' height='100%' loading='lazy' title={ innovativeQualityBottomTitle } alt={ innovativeQualityBottomTitle } />
          </div>
        </Container>
      </Container>
    </section>
  )
}

OurStrengthSection.defaultProps =
  {
    innovativeQualityTopTitle: 'Complex and',
    innovativeQualityBottomTitle: 'Innovative products',
    innovativeQualityTopText: 'From basic glass prototypes to high-end glazing products on the shelf like heated windshields, switchable glazing, coated glass, integrated lighting and more.',
    innovativeQualityBottomText: 'A large range of bending capabilities to reach the most complex shapes. We can collaborate on co-design for new concepts or new disruptive technologies from small size glass samples to large panoramic glass sunroofs.',
    innovativeQualityImage: './image/strength_section/innovative_products.jpg'
  }

OurStrengthSection.propTypes =
  {
    innovativeQualityTopTitle: PropTypes.string,
    innovativeQualityBottomTitle: PropTypes.string,
    innovativeQualityTopText: PropTypes.string,
    innovativeQualityBottomText: PropTypes.string,
    innovativeQualityImage: PropTypes.string
  }

export default OurStrengthSection
