import { useState, useEffect, useRef, Fragment } from 'react'
import Header from '../components/Header/Header'
import HeroSection from '../components/SalesLegalTermsSection/hero/hero.component'
import IntroductionSection from '../components/SalesLegalTermsSection/introduction/introduction.component'
import TermsSection from '../components/SalesLegalTermsSection/terms/terms.component'
import ConclusionSection from '../components/SalesLegalTermsSection/conclusion/conclusion.component'
import Container from '../components/Shared/Container'
import Loader from '../components/Shared/Loader'
import Background from '../threejs/Background'
import Footer from '../components/Footer/Footer'
import Navigation from '../components/Navigation/Navigation'
import CookieConsent from '../components/CookieConsent/CookieConsent'

const SalesLegalTerms = () => {
    const [ loading, setLoading ] = useState( true )
    const isMounted = useRef( true )
    useEffect( () => {
        setTimeout( () => {
            if ( isMounted.current ) {
                setLoading( false )
            }
        }, 3000 )
        return () =>
        {
            isMounted.current = false
        }
    }, [ isMounted ] )
    return (
        <Fragment>
            {
                loading
                ?
                <Loader />
                :
                <Fragment>
                    <Background />
                    <Navigation route={ 'sales-legal-terms' } />
                    <Header home={ false } />
                    <Container classes={ 'home-screen-container' }>
                        <HeroSection id={ 'top' } />
                        <IntroductionSection id={ 'introduction' } />
                        <TermsSection id={ 'terms' } />
                        <ConclusionSection id={ 'conclusion' } />
                        <Footer />
                    </Container>
                    <CookieConsent />
                </Fragment>
            }
        </Fragment>
    )
}

export default SalesLegalTerms
