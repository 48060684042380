import PropTypes from 'prop-types'
import Container from '../Shared/Container'
import { Link } from 'react-scroll'
import './Navigation.css'

function NavigationItem( { item, handleClick } ) {
    return (
        <Link to={ item.id } activeClass='active' spy={ true } offset={ -120 } onClick={ handleClick }>
            <Container classes={ 'navigation-item' } flex gapSmall alignCenter>
                <Container classes={ 'navigation-item-icon' } flex flexCenter alignCenter>
                    <img src={ item.icon } width='100%' height='100%' loading='eager' title={ item.title } alt={ item.title } />
                </Container>
                <p>{ item.title }</p>
            </Container>
        </Link>
    )
}

NavigationItem.propTypes =
    {
        item: PropTypes.object.isRequired
    }

export default NavigationItem
