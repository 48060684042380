import PropTypes from 'prop-types'
import NavigationItem from './NavigationItem'
import Container from '../Shared/Container'
import NavigationData from '../../data/NavigationData'
import { LEGAL_NOTICE_NAVIGATION_DATA } from '../../data/LegalNoticeNavigationData'
import { SALES_LEGAL_TERMS_NAVIGATION_DATA } from '../../data/SalesLegalTermsNavigationData'
import './Navigation.css'
import { Fragment } from 'react'

function NavigationList( { route } ) {

    return (
        <Fragment>
            <Container classes={ 'navigation-list' } flex flexColumn gapMedium>
                {
                    ( route === 'legal-notice' )
                    ?
                    <Fragment>
                        {   LEGAL_NOTICE_NAVIGATION_DATA.map(
                            ( item, index ) =>
                                (
                                    <NavigationItem key={ index } item={ item } />
                                )
                            )
                        }
                    </Fragment>
                    : (
                        ( route === 'sales-legal-terms' )
                        ?
                        <Fragment>
                            {   SALES_LEGAL_TERMS_NAVIGATION_DATA.map(
                                ( item, index ) =>
                                    (
                                        <NavigationItem key={ index } item={ item } />
                                    )
                                )
                            }
                        </Fragment>
                        :
                        <Fragment>
                            {   NavigationData.map(
                                ( item, index ) =>
                                    (
                                        <NavigationItem key={ index } item={ item } />
                                    )
                                )
                            }
                        </Fragment>
                    )
                }
            </Container>
        </Fragment>
    )
}

NavigationList.propTypes =
    {
        item: PropTypes.array
    }

export default NavigationList
