import { useState, useEffect, useRef, Fragment } from 'react'
import Container from '../components/Shared/Container'
import HomeSection from '../components/Section/HomeSection'
import AboutSection from '../components/Section/AboutSection'
import OurStrengthSection from '../components/Section/OurStrengthSection'
import InnovativeProductsSection from '../components/Section/InnovativeProductsSection'
import FlexibilitySection from '../components/Section/FlexibilitySection'
import StructureSection from '../components/Section/StructureSection'
import OfferSection from '../components/Section/OfferSection'
import ContactSection from '../components/Section/ContactSection'
import GallerySectionOne from '../components/Section/GallerySectionOne'
import Loader from '../components/Shared/Loader'
import Footer from '../components/Footer/Footer'
import Background from '../threejs/Background'
import Navigation from '../components/Navigation/Navigation'
import Header from '../components/Header/Header'
import CookieConsent from '../components/CookieConsent/CookieConsent'

function HomeScreen() {
    const [ loading, setLoading ] = useState( true )
    const isMounted = useRef( true )
    useEffect( () => {
        setTimeout( () => {
            if ( isMounted.current ) {
                setLoading( false )
            }
        }, 3000 )
        return () =>
        {
            isMounted.current = false
        }
    }, [ isMounted ] )
    return (
        <>
            {
                loading
                ?
                <Loader />
                :
                <Fragment>
                    <Background />
                    <Navigation route={ 'home' } />
                    <Header home={ true } />
                    <Container classes={ 'home-screen-container' }>
                        <HomeSection />
                        <AboutSection />
                        <GallerySectionOne />
                        <OurStrengthSection />
                        <InnovativeProductsSection />
                        <FlexibilitySection />
                        <StructureSection />
                        <OfferSection />
                        <ContactSection />
                        <Footer />
                    </Container>
                    <CookieConsent />
                </Fragment>
            }
        </>
    )
}

export default HomeScreen
