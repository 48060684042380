import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen'
import ErrorScreen from './screens/ErrorScreen'
import LegalNotice from './screens/LegalNotice'
import SalesLegalTerms from './screens/SalesLegalTerms'

function App() {

    return (
        <Router>

            <Routes>

                <Route exact path='/' element={ <HomeScreen /> } />
                <Route exact path='/legal-notice' element={ <LegalNotice /> } />
                <Route exact path='/sales-legal-terms' element={ <SalesLegalTerms /> } />
                <Route path='*' element={ <ErrorScreen /> } />

            </Routes>

        </Router>
    )
}

export default App
