import Title from '../../Shared/Title'
import Container from '../../Shared/Container'
import { LEGAL_NOTICE_DATA } from '../../../data/legalNoticeData'

import './miscellaneous.styles.css'

const MiscellaneousSection = ( { id } ) => {
    const { title, content } = LEGAL_NOTICE_DATA.miscellaneous

    return (
        <Container classes={ 'miscellaneous' } flex flexColumn id={ id }>
            <Title size={ 'block-title' }>
                <h5>{ title }</h5>
            </Title>
            <Container classes={ 'miscellaneous-block' } flex flexColumn>
                {
                    content.map( ( text, index ) => (
                        <p key={ index }>{ text.text }</p>
                    ) )
                }
            </Container>
        </Container>
    )
}

export default MiscellaneousSection
