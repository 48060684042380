import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Container from '../Shared/Container'
import Title from '../Shared/Title'
import UseElementOnScreen from '../../hooks/UseElementOnScreen'
import './ContactSection.css'
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api'
import { mapStyles } from '../../data/mapStyles'

const mapContainerStyle = {
  height: '100%',
  width: '100%'
}

const center = {
  lat: 50.326040,
  lng: 3.243020
}

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: false,
}

function ContactSection( {
  contactTitle,
  contactSubtitle,
  emailTitle,
  emailText,
  addressTitle,
  addressTextOne,
  addressTextTwo,
  addressTextThree,
  linkedTitle,
  linkedText,
  circleBackground,
  squareBackground
} ) {
  const targetRef = useRef( null )
  const [ address, setAddress ] = useState( false )
  const isVisible = UseElementOnScreen( {
    root: null,
    rootMargin: '0px',
    threshold: 0.2
  }, targetRef )
  const { isLoaded, loadError } = useLoadScript( {
    googleMapsApiKey: 'AIzaSyCFOCXExO8wnfZgr0p0DFPaDW3XI1lCmg4'
  } )
  if ( loadError ) return 'Error loading maps'
  if ( !isLoaded ) return 'Loading maps'

  return (
    <section id='contact'>
      <Container useRef={ targetRef } classes={ 'contact-section' }>
        <Title size={ !isVisible ? 'medium contact-section-title' : 'medium contact-section-title is-visible'}>
          <h2>{ contactTitle }</h2>
          <p>{ contactSubtitle }</p>
        </Title>
        <Container classes={ !isVisible ? 'contact-section-item-container' : 'contact-section-item-container is-visible' }>
          <Container classes={ 'contact-section-item' } flex gapSmall>
            <p className='contact-section-item-title'>{ emailTitle }</p>
            <a href='mailto:reeflect-glass@agc.com' className='contact-section-item-text'>{ emailText }</a>
          </Container>
          <Container classes={ 'contact-section-item' } flex gapSmall>
            <p className='contact-section-item-title'>{ addressTitle }</p>
            <Container classes={ 'contact-section-item-text-container' } flex flexColumn gapSmall>
              <p className='contact-section-item-text'>{ addressTextOne }</p>
              <p className='contact-section-item-text'>{ addressTextTwo }</p>
              <p className='contact-section-item-text'>{ addressTextThree }</p>
            </Container>
          </Container>
          <Container classes={ 'contact-section-item' } flex gapSmall>
            <p className='contact-section-item-title'>{ linkedTitle }</p>
            <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/reeflect-by-agc' className='contact-section-item-text'>{ linkedText }</a>
          </Container>
        </Container>
        <div className={ !isVisible ? 'contact-section-map-container' : 'contact-section-map-container is-visible' }>
          <GoogleMap className='contact-section-map'
            mapContainerStyle={ mapContainerStyle }
            zoom={ 16 }
            center={ center }
            options={ options }
          >
            <Marker
              position={ { lat: 50.326040, lng: 3.243020 } }
              icon={ {
                url: './image/icon/map_marker.svg',
                origin: new window.google.maps.Point( 0, 0 ),
                anchor: new window.google.maps.Point( 0, 0 )
              } }
              onClick={ () => setAddress( true ) }
            >
              { address && (
                <InfoWindow>
                  <div style={ {
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '2rem'
                  } }>
                    <h3 style={ {
                      fontSize: '1.5rem'
                    } }>Reeflect</h3>
                    <p style={ {
                        fontSize: '0.75rem',
                        marginTop: '0.5rem'
                    } }>
                      address: Rue de la verrerie d'en-bas
                    </p>
                    <p style={ {
                        fontSize: '0.75rem',
                        marginTop: '0.5rem'
                    } }>
                      59580 Aniche
                    </p>
                    <p style={ {
                        fontSize: '0.75rem',
                        marginTop: '0.5rem'
                    } }>
                      France
                    </p>
                  </div>
                </InfoWindow>
              ) }
            </Marker>
          </GoogleMap>
        </div>
        <div className={ !isVisible ? 'contact-section-background-top' : 'contact-section-background-top is-visible' } />
        <div className={ !isVisible ? 'contact-section-background-bottom' : 'contact-section-background-bottom is-visible' } />
        <div className={ !isVisible ? 'contact-section-background-right' : 'contact-section-background-right is-visible' } />
        <div className={ !isVisible ? 'contact-section-background-circle' : 'contact-section-background-circle is-visible' }>
          <img src={ circleBackground } width='100%' height='100%' loading='lazy' title='circle' alt='circle' />
        </div>
        <div className={ !isVisible ? 'contact-section-background-square' : 'contact-section-background-square is-visible' }>
          <img src={ squareBackground } width='100%' height='100%' loading='lazy' title='square' alt='square' />
        </div>
      </Container>
    </section>
  )
}

ContactSection.defaultProps =
  {
    contactTitle: 'Contact Us',
    contactSubtitle: 'Tell us where you want to be. We will help you get there.',
    emailTitle: 'Email:',
    emailText: 'reeflect-glass@agc.com',
    addressTitle: 'Address:',
    addressTextOne: 'Rue de la verrerie d\'en-bas',
    addressTextTwo: '59580 Aniche',
    addressTextThree: 'France',
    linkedTitle: 'LinkedIn:',
    linkedText: 'reeflect-by-agc',
    circleBackground: './image/contact/circle_background.svg',
    squareBackground: './image/contact/square_background.svg'
  }

ContactSection.propTypes =
  {
    contactTitle: PropTypes.string,
    contactSubtitle: PropTypes.string,
    emailTitle: PropTypes.string,
    emailText: PropTypes.string,
    addressTitle: PropTypes.string,
    addressTextOne: PropTypes.string,
    addressTextTwo: PropTypes.string,
    addressTextThree: PropTypes.string,
    linkedTitle: PropTypes.string,
    linkedText: PropTypes.string,
    circleBackground: PropTypes.string,
    squareBackground: PropTypes.string
  }

export default ContactSection
