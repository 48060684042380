import { useRef } from 'react'
import Container from "../components/Shared/Container"
import LinkButton from '../components/Shared/LinkButton'
import UseElementOnScreen from '../hooks/UseElementOnScreen'
import Footer from '../components/Footer/Footer'

function ErrorScreen() {
  const targetRef = useRef( null )
  const isVisible = UseElementOnScreen( {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
  }, targetRef )
  return (
    <>
      <Container useRef={ targetRef } classes={ 'error-page' } flex flexColumn flexCenter alignCenter gapLarge>
        <h1 className={ !isVisible ? 'error-page-title' : 'error-page-title is-visible' }>#404</h1>
        <p>Page not found</p>
        <LinkButton state={ !isVisible ? 'primary' : 'primary is-visible' } linkTo='/'>Back Home</LinkButton>
      </Container>
      <Footer />
    </>
  )
}

export default ErrorScreen
