import { useRef } from 'react'
import PropTypes from 'prop-types'
import Container from '../Shared/Container'
import Title from '../Shared/Title'
import UseElementOnScreen from '../../hooks/UseElementOnScreen'
import './AboutSection.css'

function AboutSection( { topTitle, bottomTitle, textAboutTopLeft, textAboutTopRight, textAboutBottomLeft, teamImage, centerImage } ) {
    const targetRef = useRef( null )
    const isVisible = UseElementOnScreen( {
        root: null,
        rootMargin: '0px',
        threshold: 0.2
    }, targetRef )
    return (
        <section id='about'>
            <Container useRef={ targetRef } classes={ 'about-section' } flex flexColumn flexCenter>
                <Title size={ !isVisible ? 'medium' : 'medium is-visible'}>
                    <h3>{ topTitle }</h3>
                    <h4>{ bottomTitle }</h4>
                </Title>
                <Container classes={ 'about-section-grid' }>
                    <div className={ !isVisible ? 'about-section-grid-item-top-left' : 'about-section-grid-item-top-left is-visible' }>
                        <p className={ !isVisible ? 'about-section-grid-item-top-left-text' : 'about-section-grid-item-top-left-text is-visible' }>
                            { textAboutTopLeft }
                        </p>
                    </div>
                    <div className={ !isVisible ? 'about-section-grid-item-top-right' : 'about-section-grid-item-top-right is-visible' }>
                        <p className={ !isVisible ? 'about-section-grid-item-top-right-text' : 'about-section-grid-item-top-right-text is-visible' }>
                            { textAboutTopRight }
                        </p>
                    </div>
                    <div className={ !isVisible ? 'about-section-grid-item-bottom-left' : 'about-section-grid-item-bottom-left is-visible' }>
                        <p className={ !isVisible ? 'about-section-grid-item-bottom-left-text' : 'about-section-grid-item-bottom-left-text is-visible' }>
                            { textAboutBottomLeft }
                        </p>
                    </div>
                    <div className={ !isVisible ? 'about-section-grid-item-bottom-right' : 'about-section-grid-item-bottom-right is-visible' }>
                        <img className={ !isVisible ? 'about-section-team-image' : 'about-section-team-image is-visible' } src={ teamImage } width='100%' height='100%' loading='lazy' title={ topTitle } alt={ topTitle } />
                    </div>
                    <div className={ !isVisible ? 'about-section-image' : 'about-section-image is-visible' }>
                        <img src={ centerImage } width='100%' height='100%' loading='lazy' title={ topTitle } alt={ topTitle } />
                    </div>
                </Container>
            </Container>
        </section>
    )
}

AboutSection.defaultProps =
    {
        topTitle: 'Who are',
        bottomTitle: 'We?',
        textAboutTopLeft: 'Reeflect, an initiative of AGC Automotive, the worldwide leader in automotive glazing, offers state of the art expertise and capabilities to support the development of your on-demand glass prototypes.',
        textAboutTopRight: 'Whether you need fast traditional automotive glazing prototypes or a more complex innovative glass product with automotive grade quality, Reeflect is ready to partner with you on any project or journey.',
        textAboutBottomLeft: 'As we belong to AGC Automotive Europe’s Research and Development department, our DNA is based on innovations and technical challenges with a strong expertise on glazing products and processes.',
        teamImage: './image/about_section/team.jpg',
        centerImage: './image/about_section/about_section_image.svg'
    }

AboutSection.propTypes =
    {
        topTitle: PropTypes.string,
        bottomTitle: PropTypes.string,
        textAboutTopLeft: PropTypes.string,
        textAboutTopRight: PropTypes.string,
        textAboutBottomLeft: PropTypes.string,
        teamImage: PropTypes.string,
        centerImage: PropTypes.string
    }

export default AboutSection
