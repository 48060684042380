import Container from '../../Shared/Container'
import Title from '../../Shared/Title'
import './hero.styles.css'

const HeroSection = ( { id } ) => {
    return (
        <Container classes={ 'hero-section' } flex spaceBetween id={ id }>
            <Title size={ 'large is-visible' }>
                <h1>Legal</h1>
                <h2>Notice</h2>
            </Title>
        </Container>
    )
}

export default HeroSection
