import { useState, useEffect, useRef, Fragment } from 'react'
import Header from '../components/Header/Header'
import CookiesSection from '../components/LegalNoticeSection/cookies/cookies.component'
import GeneralConditions from '../components/LegalNoticeSection/general-conditions/general.conditions.component'
import HeroSection from '../components/LegalNoticeSection/hero/hero.component'
import IdentificationSection from '../components/LegalNoticeSection/identification/identification.component'
import MiscellaneousSection from '../components/LegalNoticeSection/miscellaneous/miscellaneous.component'
import PreservationSection from '../components/LegalNoticeSection/preservation/preservation.component'
import PrivacySection from '../components/LegalNoticeSection/privacy/privacy.componenent'
import Container from '../components/Shared/Container'
import Loader from '../components/Shared/Loader'
import Background from '../threejs/Background'
import Footer from '../components/Footer/Footer'
import Navigation from '../components/Navigation/Navigation'
import CookieConsent from '../components/CookieConsent/CookieConsent'

const LegalNotice = () => {
    const [ loading, setLoading ] = useState( true )
    const isMounted = useRef( true )
    useEffect( () => {
        setTimeout( () => {
            if ( isMounted.current ) {
                setLoading( false )
            }
        }, 3000 )
        return () =>
        {
            isMounted.current = false
        }
    }, [ isMounted ] )
    return (
        <Fragment>
            {
                loading
                ?
                <Loader />
                :
                <Fragment>
                    <Background />
                    <Navigation route={ 'legal-notice' } />
                    <Header home={ false } />
                    <Container classes={ 'home-screen-container' }>
                        <HeroSection id={ 'top' } />
                        <IdentificationSection id={ 'identification' } />
                        <GeneralConditions id={ 'general' } />
                        <PrivacySection id={ 'privacy' } />
                        <CookiesSection id={ 'cookies' } />
                        <PreservationSection id={ 'preservation' } />
                        <MiscellaneousSection id={ 'miscellaneous' } />
                        <Footer />
                    </Container>
                    <CookieConsent />
                </Fragment>
            }
        </Fragment>
    )
}

export default LegalNotice
