import { useRef } from 'react'
import PropTypes from 'prop-types'
import Container from '../Shared/Container'
import Title from '../Shared/Title'
import UseElementOnScreen from '../../hooks/UseElementOnScreen'
import './HomeSection.css'

function HomeSection( { topTitle, bottomTitle, text, icon } ) {
    const targetRef = useRef( null )
    const isVisible = UseElementOnScreen( {
        root: null,
        rootMargin: '0px',
        threshold: 0.2
    }, targetRef )
    return (
        <section id='home'>
            <Container useRef={ targetRef } classes={ 'home-section' } flex spaceBetween>
                <Container classes={ 'home-section-left' } flex alignCenter>
                    <Title size={ !isVisible ? 'large' : 'large is-visible' }>
                        <h1>{ topTitle }</h1>
                        <h2>{ bottomTitle }</h2>
                    </Title>
                    <div className={ !isVisible ? 'home-section-background-item' : 'home-section-background-item is-visible' } />
                </Container>
                <Container classes={ 'home-section-right' } flex flexCenter alignEnd>
                    <Container classes={ 'home-section-right-content' } flex flexColumn>
                        <div className={ !isVisible ? 'home-section-right-icon' : 'home-section-right-icon is-visible' }>
                            <img src={ icon } width='100%' height='100%' loading='lazy' title={ topTitle } alt={ topTitle } />
                        </div>
                        <p className={ !isVisible ? 'home-section-right-text' : 'home-section-right-text is-visible' }>{ text }</p>
                    </Container>
                </Container>
            </Container>
        </section>
    )
}

HomeSection.defaultProps =
    {
        topTitle: 'Glass prototyping',
        bottomTitle: 'Experts',
        text: 'Our DNA is based on innovations and technical challenges with a strong expertise on glazing products and processes.',
        icon: './image/home_section/home_section_icon.svg'
    }

HomeSection.propTypes =
    {
        topTitle: PropTypes.string,
        bottomTitle: PropTypes.string,
        subtitle: PropTypes.string,
        icon: PropTypes.string
    }

export default HomeSection
