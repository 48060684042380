import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const ButtonMailto = ( { mailto, children, state, isDisabled, type, leftImage, rightImage } ) => {
    return (
        <Link
            type={ type }
            disabled={ isDisabled }
            className={ `btn btn-${ state }` }
            to='#'
            onClick={ ( e ) => {
                window.location = mailto
                e.preventDefault()
            } }
        >
            <img src={ leftImage } width='100%' height='100%' loading='lazy' title={ children } alt={ children } />
                { children }
            <img src={ rightImage } width='100%' height='100%' loading='lazy' title={ children } alt={ children } />
        </Link>
    )
}

ButtonMailto.defaultProps =
    {
        state: 'primary',
        type: 'button',
        isDisabled: false,
        leftImage: './image/button/left_image.svg',
        rightImage: './image/button/right_image.svg'
    }

ButtonMailto.propTypes =
    {
        children: PropTypes.node.isRequired,
        state: PropTypes.string,
        type: PropTypes.string,
        isDisabled: PropTypes.bool,
        leftImage: PropTypes.string,
        rightImage: PropTypes.string
    }

export default ButtonMailto
