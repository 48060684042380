import { useRef } from 'react'
import PropTypes from 'prop-types'
import Container from '../Shared/Container'
import Title from '../Shared/Title'
import UseElementOnScreen from '../../hooks/UseElementOnScreen'
import './OurStrengthSection.css'

function OurStrengthSection( {
    structureTopTitle,
    structureBottomTitle,
    structureText,
    structureImage,
    circleElement
  } ) {
  const targetRef = useRef( null )
  const isVisible = UseElementOnScreen( {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
  }, targetRef )
  return (
    <section>
      <Container useRef={ targetRef } classes={ 'our-strength-section' } flex flexColumn alignCenter>
        <Container classes={ 'our-strength-section-block-structure' } flex>
          <div className='our-strength-section-structure-quality-content-container'>
            <Title size={ !isVisible ? 'small' : 'small is-visible' }>
              <h6>{ structureTopTitle }</h6>
              <h5>{ structureBottomTitle }</h5>
            </Title>
            <p className={ !isVisible ? 'our-strength-section-structure-quality-content-text' : 'our-strength-section-structure-quality-content-text is-visible' }>{ structureText }</p>
            <div className={ !isVisible ? 'our-strength-section-structure-quality-content-background' : 'our-strength-section-structure-quality-content-background is-visible' } />
          </div>
          <div className={ !isVisible ? 'our-strength-section-block-structure-image-container' : 'our-strength-section-block-structure-image-container is-visible' }>
            <img src={ structureImage } className={ !isVisible ? 'our-strength-section-structure-quality-image' : 'our-strength-section-structure-quality-image is-visible' } width='100%' height='100%' loading='lazy' title={ structureBottomTitle } alt={ structureBottomTitle } />
          </div>
          <div className={ !isVisible ? 'our-strength-section-block-structure-element-container' : 'our-strength-section-block-structure-element-container is-visible' }>
            <img src={ circleElement } className='our-strength-section-block-structure-element-left' width='100%' height='100%' loading='lazy' title={ structureBottomTitle } alt='structure' />
          </div>
        </Container>
      </Container>
    </section>
  )
}

OurStrengthSection.defaultProps =
  {
    structureTopTitle: 'The AGC group',
    structureBottomTitle: 'Structure',
    structureText: 'We benefit from an extended network of internal and external partners to fit to your glass prototypes needs: tooling, special cutting, tempering, coating, etc…',
    structureImage: './image/strength_section/structure_quality.jpg',
    circleElement: './image/elements/circle.svg'
  }

OurStrengthSection.propTypes =
  {
    structureTopTitle: PropTypes.string,
    structureBottomTitle: PropTypes.string,
    structureText: PropTypes.string,
    structureImage: PropTypes.string,
    circleElement: PropTypes.string
  }

export default OurStrengthSection
