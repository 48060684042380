import { useEffect, useRef } from 'react'

import * as THREE from 'three'

import { fragmentShader } from './shaders/fragment.js'
import { vertexShader } from './shaders/vertex.js'
// import { fragmentShaderSphere } from './shaders/fragmentSphere.js'
// import { vertexShaderSphere } from './shaders/vertexSphere.js'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'


function Background() {

  const backgroundRef = useRef( null )

  useEffect( () =>
  {
    let scene = new THREE.Scene()

    const lerp = 
    (
      start,
      end,
      t
    ) =>
    {
      return start * ( 1 - t ) + end * t
    }

    let offset = new THREE.Vector2( 0, 0 )

    let background = backgroundRef.current
    let perspective = 0.8
    let height = window.innerHeight
    let width = window.innerWidth

    let targetX = 0
    let targetY = 0

    let fov = ( 180 * Math.atan( height / 2 / perspective ) ) / Math.PI
    let camera = new THREE.PerspectiveCamera( fov, width / height, 0.001, 1000 )
    camera.position.set( 0, 0, perspective )

    let renderer = new THREE.WebGL1Renderer( { antialias: true, alpha: true } )
    renderer.setSize( width, height )
    renderer.setPixelRatio( Math.min( window.devicePixelRatio, 2 ) )

    let composer = new EffectComposer( renderer )
    composer.addPass( new RenderPass( scene, camera ) )

    background.appendChild( renderer.domElement )

    let geometry = new THREE.SphereBufferGeometry( 1.5, 32, 32 )

    let material = new THREE.ShaderMaterial
    ( 
      {
        extensions:
        {
          derivatives: '#extention GL_OES_standart_derivatives: enable'
        },
        side: THREE.DoubleSide,
        uniforms:
        {
          time: { value: 0 },
          resolution: { value: new THREE.Vector4() }
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader
        } 
    )

    let mesh = new THREE.Mesh( geometry, material )
    mesh.position.set( offset.x, offset.y )

    scene.add( mesh )

    // let cubeRenderTarget = new THREE.WebGLCubeRenderTarget
    // ( 
    //   256, 
    //   {
    //     format: THREE.RGBAFormat,
    //     generateMipmaps: true,
    //     minFilter: THREE.LinearMipMapLinearFilter,
    //     encoding: THREE.sRGBEncoding
    //   } 
    // )

    // let cubeCamera = new THREE.CubeCamera( 0.1, 10, cubeRenderTarget )

    // let geometrySphere = new THREE.SphereBufferGeometry( 0.45, 64, 64 )

    // let materialSphere = new THREE.ShaderMaterial
    // (
    //   {
    //     extensions:
    //     {
    //       derivatives: '#extention GL_OES_standart_derivatives: enable'
    //     },
    //     side: THREE.DoubleSide,
    //     uniforms:
    //     {
    //       time: { value: 0 },
    //       tCube: { value: 0 },
    //       resolution: { value: new THREE.Vector4() }
    //     },
    //     vertexShader: vertexShaderSphere,
    //     fragmentShader: fragmentShaderSphere
    //   }
    // )

    // let smallSphere = new THREE.Mesh( geometrySphere, materialSphere )

    // scene.add( smallSphere )

    const render = () => 
    {     
      material.uniforms.time.value += 0.004

      // smallSphere.visible = false
      // cubeCamera.update( renderer, scene )
      // smallSphere.visible = true

      offset.x = lerp( offset.x, targetX, 0.006 )
      offset.y = lerp( offset.y, targetY, 0.006 )
      
      camera.lookAt( offset.x - ( window.innerWidth / 2000 ), - offset.y + ( window.innerHeight / 2000 ) )
      
      // materialSphere.uniforms.tCube.value = cubeRenderTarget.texture
      requestAnimationFrame( render )

      composer.render( scene, camera )
    }

    const onWindowResize = () => 
    {
      camera.aspect = width / height
      camera.fov = ( 180 * Math.atan( height / 2 / perspective ) ) / Math.PI
      camera.updateProjectionMatrix()
      renderer.setSize( width, height )
      renderer.setPixelRatio( Math.min( window.devicePixelRatio, 2 ) )
    }
    
    //Events
    window.addEventListener( 'resize', onWindowResize, false )
    
    window.addEventListener( 'mousemove', ( e ) => 
      {
        targetX = e.clientX / 800
        targetY = e.clientY / 800
      } 
    )

    render()

    return () => background.removeChild( renderer.domElement )

  }, [] )

  return (
    <>
      <div className='background' ref={ backgroundRef }></div>
      <div className='background-vignette'></div>
    </>
  )
}

export default Background