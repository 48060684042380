import Container from '../../Shared/Container'
import { SALES_LEGAL_TERMS_DATA } from '../../../data/salesLegalTermsData'

import './conclusion.styles.css'

const ConclusionSection = ( { id } ) => {
    const { content } = SALES_LEGAL_TERMS_DATA.conclusion

    return (
        <Container classes={ 'conclusion-section' } flex flexColumn id={ id }>
        {
            content.map( ( paragraph, index ) => (
                <p key={ index }>{ paragraph.text }</p>
            ) )
        }
        </Container>
    )
}

export default ConclusionSection
