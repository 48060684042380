import { useRef } from 'react'
import PropTypes from 'prop-types'
import Container from '../Shared/Container'
import UseElementOnScreen from '../../hooks/UseElementOnScreen'
import './GallerySection.css'

function GallerySectionOne( { firstSlide, secondSlide } ) {
    const targetRef = useRef( null )
    const isVisible = UseElementOnScreen( {
        root: null,
        rootMargin: '0px',
        threshold: 0.2
    }, targetRef )
    return (
        <section>
            <Container useRef={ targetRef } classes={ 'gallery-section-one' } flex>
                <Container classes={ !isVisible ? 'gallery-section-slide-container-one' : 'gallery-section-slide-container-one is-visible' }>
                    <img src={ firstSlide } className={ !isVisible ? 'gallery-section-slide-one' : 'gallery-section-slide-one is-visible' } width='100%' height='100%' loading='lazy' title='glass prototype slide' alt='slide' />
                </Container>
                <Container classes={ !isVisible ? 'gallery-section-slide-container-one' : 'gallery-section-slide-container-one is-visible' }>
                    <img src={ secondSlide } className={ !isVisible ? 'gallery-section-slide-two' : 'gallery-section-slide-two is-visible' } width='100%' height='100%' loading='lazy' title='glass prototype slide' alt='slide' />
                </Container>
            </Container>
        </section>
    )
}

GallerySectionOne.defaultProps =
    {
        firstSlide: './image/gallery/slide_one.jpg',
        secondSlide: './image/gallery/slide_two.jpg'
    }

GallerySectionOne.propTypes =
    {
        firstSlide: PropTypes.string,
        secondSlide: PropTypes.string
    }

export default GallerySectionOne
